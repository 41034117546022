import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { autoPlacement, autoUpdate, offset, safePolygon, shift, useDismiss, useFloating, useHover, useInteractions, useRole, useTransitionStyles } from '@floating-ui/react'
import { Icon } from '/features/buildingBlocks/Icon'
import { useLocationMatch } from '@kaliber/routing'
import * as flagIcons from '/domain/flagIcons'
import { routeMap } from '/routeMap'
import { Logout } from './Logout'
import { determineLanguageAndCountry } from '/i18n/utils'
import { refToDataXLink } from '/machinery/tracking/pushToDataLayer'

import logo from '/images/logo.raw.svg'
import styles from './DesktopMenu.css'

export function DesktopMenu({ theme, items, onOpenChange, userInfo, useLargeBreakpoint = false, layoutClassName = undefined }) {
  const { params: { language: locale } } = useLocationMatch()
  const [language, country] = determineLanguageAndCountry(locale || '')
  const { isLoggedIn = false } = userInfo || {}

  return (
    <div className={cx(
      styles.component,
      useLargeBreakpoint && styles.useLargeBreakpoint,
      isLoggedIn && styles.withLoginItem,
      layoutClassName
    )}>
      <RitualsLogo />
      <MenuList {...{ theme, items, isLoggedIn }} />
      {isLoggedIn && <Logout layoutClassName={styles.logoutLayout} />}
      <button data-x='click-to-open-language-modal' onClick={onOpenChange} className={styles.languageButton}>
        <Icon icon={flagIcons[country]} />
        {language.toUpperCase()}
      </button>
    </div>
  )
}

function RitualsLogo() {
  const { params: { language: locale } } = useLocationMatch()

  return (
    <a
      aria-label="logo"
      data-x='link-to-home'
      aria-roledescription="logo"
      href={routeMap.app.home({ language: locale })}
      className={styles.componentRitualsLogo}
    >
      <span role="presentation" dangerouslySetInnerHTML={{ __html: logo }} />
    </a>
  )
}

function MenuList({ theme, items, isLoggedIn }) {
  return (
    <ul className={styles.componentMenuList}>
      {items.map((x, i) => {
        const { item, menuItemType, items, protectedItems } = x ?? {}
        const url = item.ref ? determineDocumentPathSyncWithParams({ document: item.ref, routeMap, params: item.params }) : ''
        const dataX = refToDataXLink(item.ref, { prefix: 'nav' })

        switch (menuItemType) {
          case 'self':
            return <MenuItem key={i} label={item.label} {...{ url, dataX }} />
          case 'subMenuItems':
            return <ExpandableItem key={i} label={item.label} {...{ url, dataX, items, protectedItems, isLoggedIn, theme }} />
          default:
            return null
        }
      })}
    </ul>
  )
}

function ExpandableItem({ theme, label, url, dataX, items, protectedItems, isLoggedIn }) {
  const { reference, floating, content } = useFloatingSubMenu()

  return (
    <>
      <MenuItem aRef={reference.ref} {...reference.props} {...{ url, dataX, label }} />

      {floating.isMounted && (
        <div ref={floating.ref} {...floating.props} data-style-context={theme}>
          <SubMenu {...{ items, protectedItems, isLoggedIn }} animation={content.animation} />
        </div>
      )}
    </>
  )
}

function MenuItem({ label, url, dataX, aRef = undefined, ...rest }) {
  return (
    <a
      ref={aRef}
      href={url}
      data-x={dataX}
      className={styles.componentMenuItem}
      {...rest}
    >
      {label}
    </a>
  )
}

function MenuItems({ items }) {
  return items?.map(x => (
    <li key={x._key}>
      <MenuItem
        label={x.label}
        dataX={refToDataXLink(x.ref, { prefix: 'nav' })}
        url={x.ref ?  determineDocumentPathSyncWithParams({ document: x.ref, routeMap, params: x.params }) : ''}
      />
    </li>
  ))
}

function SubMenu({ animation, items, protectedItems, isLoggedIn }) {
  return (
    <ul className={styles.componentSubMenu} style={animation}>
      {Boolean(items?.length) &&
        <MenuItems {...{ items }} />
      }
      {isLoggedIn && Boolean(protectedItems?.length) &&
        <MenuItems items={protectedItems} />
      }
    </ul>
  )
}

function useFloatingSubMenu() {
  const [isOpen, setIsOpen] = React.useState(false)
  const { refs, context, floatingStyles } = useFloating({
    transform: true,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement(),
      offset({ mainAxis: 25 }),
      shift({ padding: 5 })
    ],
  })

  const role = useRole(context, { role: 'menu' })
  const dismiss = useDismiss(context)
  const hover = useHover(context, { handleClose: safePolygon() })

  const { getFloatingProps, getReferenceProps } = useInteractions([
    role,
    dismiss,
    hover
  ])

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: { transform: `translateY(-5px)`, opacity: 0 },
    duration: 200,
  })

  return {
    reference: {
      props: getReferenceProps(),
      ref: refs.setReference,
    },
    floating: {
      props: {
        ...getFloatingProps(),
        style: floatingStyles,
      },
      ref: refs.setFloating,
      isMounted,
    },
    content: {
      animation: transitionStyles
    }
  }
}
