import { FloatingOverlay as FloatingOverlayBase, useFloating, FloatingFocusManager, autoUpdate, useRole, useDismiss, useTransitionStyles, useInteractions, FloatingPortal } from '@floating-ui/react'
import { useLocationMatch } from '@kaliber/routing'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useClientConfig } from '/machinery/ClientConfig'
import { installedLanguages } from '/installedLanguageLookup'
import { countryWithLocales } from '/i18n/countryWithLocales'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import { HeadingSm } from '/features/buildingBlocks/Heading'
import { Dropdown } from '/features/buildingBlocks/Dropdown'
import { Button, ButtonGhost } from '/features/buildingBlocks/Button'
import { Label } from '/features/buildingBlocks/Label'
import { Icon } from '/features/buildingBlocks/Icon'
import * as flagIcons from '/domain/flagIcons'

import iconClose from '/images/icons/close.raw.svg'
import ritualsLogo from '/images/logo.png'

import mediaStyles from '/cssGlobal/media.css'
import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './LanguageSelection.css'

function LanguagesAndCountriesSelection({ translations }) {
  const { selectedCountry, setSelectedCountry, selectedLanguage, setSelectedLanguage, languages } = useSelectedCountryAndLanguage()
  const redirectToLanguage = useRedirectToLanguage(translations)
  const { countryDefaultLocale } = useClientConfig()

  return (
    <>
      <Countries {...{ selectedCountry }} onCountryChange={handleSelectCountry} />
      <Languages {...{ selectedLanguage, languages }} onLanguageChange={setSelectedLanguage} />

      <SubmitButton
        onClick={handleSubmit}
        disabled={!selectedCountry || !selectedLanguage}
        layoutClassName={styles.buttonLayout}
      />
    </>
  )

  function handleSelectCountry(country) {
    setSelectedCountry(country)
    const language = country.languages.find(x => x.language === countryDefaultLocale[country.countryCode])
    setSelectedLanguage(language)
  }

  function handleSubmit() {
    if (selectedLanguage) redirectToLanguage(selectedLanguage.language)
  }
}

export function LanguageSelection({ translations, layoutClassName }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <LanguagesAndCountriesSelection {...{ translations }} />
    </div>
  )
}

export function LanguageSelectionModal({ open, onOpenChange, translations }) {
  const {
    context,
    isMounted,
    floatingProps,
    referenceProps
  } = useFloatingModal({ open, onOpenChange })

  return (
    <FloatingPortal id='language-selection-root'>
      {isMounted && (
        <FloatingOverlayBase lockScroll className={cx(styles._rootModal, styles.overlay)} {...referenceProps}>
          <FloatingFocusManager visuallyHiddenDismiss modal {...{ context }}>
            <ModalRef
              layoutClassName={styles.modalLayout}
              {...{ onOpenChange, translations }}
              {...floatingProps}
            />
          </FloatingFocusManager>
        </FloatingOverlayBase>
      )}
    </FloatingPortal>
  )
}

const ModalRef = React.forwardRef(Modal)
function Modal({ layoutClassName, onOpenChange, translations, ...rest }, ref) {
  return (
    <div
      data-style-context={cssStyleContext.contextWhite}
      className={cx(styles.componentModal, layoutClassName)}
      {...{ ref }}
      {...rest}
    >
      <button data-x='click-to-close-language-modal' onClick={() => onOpenChange(false)} className={styles.closeButton}>
        <Icon icon={iconClose} />
      </button>

      <Logo />
      <Heading layoutClassName={styles.headingLayout} />
      <LanguagesAndCountriesSelection {...{ translations }} />
    </div>
  )
}

function Logo() {
  return (
    <div className={styles.componentLogo}>
      <img role="presentation" alt="" src={ritualsLogo} />
    </div>
  )
}

function Heading({ layoutClassName }) {
  return (
    <div className={cx(styles.componentHeading, layoutClassName)}>
      <HeadingSm h='3' title='Select country and language' />
    </div>
  )
}

function Countries({ selectedCountry, onCountryChange }) {
  const { __ } = useTranslate()

  const countryOptions = countryWithLocales.map(x => ({
    icon: flagIcons[x.flagIcon],
    label: x.countryTitle,
    value: x,
  }))

  return (
    <div className={styles.componentCountries}>
      <Label text={__`country`} htmlFor={__`country`} />
      <Dropdown
        id={__`country`}
        value={selectedCountry}
        onChange={onCountryChange}
        options={countryOptions}
        valueSelector='countryCode'
      />
    </div>
  )
}

function Languages({ selectedLanguage, languages, onLanguageChange }) {
  const { __ } = useTranslate()
  const languageOptions = languages.map(x => ({ label: x.title, value: x }))

  return (
    <div className={styles.componentLanguages}>
      <Label text={__`language`} htmlFor={__`language`} />
      <Dropdown
        id={__`language`}
        disabled={!languageOptions.length}
        hasSingleItem={!(languageOptions?.length > 1)}
        value={selectedLanguage}
        onChange={onLanguageChange}
        options={languageOptions}
        valueSelector='language'
      />
    </div>
  )
}

function SubmitButton({ disabled, onClick, layoutClassName }) {
  const { __ } = useTranslate()
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false
  const ButtonComponent = isMobile ? ButtonGhost : Button

  return (
    <ButtonComponent dataX="submit" {...{ disabled, layoutClassName, onClick }} >
      {__`component-Modal-continue`}
    </ButtonComponent>
  )
}

function useSelectedCountryAndLanguage() {
  const { params: { language: locale } } = useLocationMatch()
  const [selectedCountry, setSelectedCountry] = useCountryState(locale)
  const languages = selectedCountry?.languages || []
  const initialLanguage = languages.find(x => x.language === locale)
  const [selectedLanguage, setSelectedLanguage] = React.useState(initialLanguage)

  return { selectedCountry, setSelectedCountry, selectedLanguage, setSelectedLanguage, languages }
}

function useCountryState(locale) {
  const [selectedCountry, setSelectedCountry] = React.useState(() =>
    countryWithLocales.find(x => x.languages.some(x => x.language === locale)) || null
  )

  return /** @type {const} */ ([selectedCountry, setSelectedCountry])
}

function useRedirectToLanguage(translations) {
  const { route, params } = useLocationMatch()
  return locale => {
    const document = translations.find(x => x.language === installedLanguages[locale])

    window.location.href = document?.slug?.current
      ? determineDocumentPathSync({ document, routeMap })
      : route({ ...params, language: locale })
  }
}

function useFloatingModal({ open, onOpenChange }) {
  const { refs, context } = useFloating({
    open,
    onOpenChange,
    whileElementsMounted: autoUpdate,
  })

  const dismiss = useDismiss(context, { escapeKey: true })
  const role = useRole(context)

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: 400,
    initial: {
      opacity: 0
    },
  })

  const { styles: overlayStyles } = useTransitionStyles(context, {
    duration: 200,
    initial: {
      opacity: 0
    },
  })

  const { getFloatingProps } = useInteractions([
    dismiss,
    role,
  ])

  return {
    referenceProps: {
      style: overlayStyles
    },
    floatingProps: {
      ref: refs.setFloating,
      style: transitionStyles,
      ...getFloatingProps(),
    },
    isMounted,
    context,
  }
}
