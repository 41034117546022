import { FloatingOverlay as FloatingOverlayBase, FloatingPortal, useFloating, useInteractions, useRole } from '@floating-ui/react'

import { useScrollActivity } from '/machinery/useScrollActivity'
import { useScrollDepth } from '/machinery/useScrollDepth'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'
import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { animated, easings, useTrail, useTransition } from 'react-spring'
import { LanguageSelection } from './LanguageSelection'
import { routeMap } from '/routeMap'
import { Logout } from './Logout'
import { refToDataXLink } from '/machinery/tracking/pushToDataLayer'

import iconChevronRightBoxed from '/images/icons/chevron-right-boxed.raw.svg'
import iconChevronLeftBoxed from '/images/icons/chevron-left-boxed.raw.svg'
import iconHamburger from '/images/icons/hamburger.raw.svg'
import iconClose from '/images/icons/close.raw.svg'
import logo from '/images/logo.raw.svg'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './MobileMenu.css'

export function MobileMenu({ items, userInfo, translations, layoutClassName = undefined }) {
  const { setIsOpen, isOpen, referenceProps, floatingProps } = useFloatingMenu()
  const [selectedItem, setSelectedItem] = React.useState(null)
  const { hasScrolled } = useScrollActivity({ delta: 100 })
  const useDarkBackground = useScrollDepth()
  const { __ } = useTranslate()

  const { isLoggedIn = false } = userInfo || {}

  const transition = useTransition(selectedItem, {
    config: { duration: 150, easing: easings.easeInOutCubic },
    from: { opacity: 0, x: 5 },
    enter: { opacity: 1, x: 0 },
    leave: { opacity: 0, x: 5 }
  })

  return (
    <div
      className={cx(
        styles.component,
        !hasScrolled && styles.isOpen,
        useDarkBackground && styles.useDarkBackground,
        layoutClassName
      )}
    >
      <div className={styles.menuContainer}>
        <RitualsLogo layoutClassName={styles.logoLayout} />
        <button
          aria-label={__`open-menu-button`}
          data-x='click-to-open-mobile-menu'
          className={styles.hamburgerButton}
          onClick={() => setIsOpen(!isOpen)}
          {...referenceProps}
        >
          <Icon icon={iconHamburger} />
        </button>
      </div>

      <div {...floatingProps}>
        {isOpen && (
          <FloatingPortal id='mobile-menu-root'>
            <FloatingOverlayBase
              lockScroll
              data-style-context={cssStyleContext.contextBlack}
              className={styles._rootOverlay}
            >
              <div className={styles.container}>
                <CloseIcon onClick={handleClose} layoutClassName={styles.closeIconLayout} />
                <RitualsLogo layoutClassName={styles.logoLayout} />

                {transition((style, item) => (
                  <animated.div className={styles.menuListLayout} {...{ style }}>
                    {item
                      ? <SubMenu onPressBack={handleBack} {...{ item, isLoggedIn }} />
                      : <MenuList onItemSelected={handleSelect} {...{ items }} />
                    }
                  </animated.div>
                ))}

                {isLoggedIn && <Logout layoutClassName={styles.logoutLayout} />}
                {!selectedItem && (
                  <LanguageSelection {...{ translations }} layoutClassName={styles.languageSelectionLayout} />
                )}
              </div>
            </FloatingOverlayBase>
          </FloatingPortal>
        )}
      </div>
    </div>
  )

  function handleClose() {
    setIsOpen(false)
  }

  function handleBack() {
    handleSelect(null)
  }

  function handleSelect(x) {
    setSelectedItem(x)
  }
}

function SubMenu({ item: menuItem, onPressBack, isLoggedIn, layoutClassName = undefined }) {
  const { item, items = [], protectedItems = [] } = menuItem

  const submenuItems = [
    item,
    ...items,
    ...(isLoggedIn ? (protectedItems || []) : [])
  ]

  return (
    <div className={cx(styles.componentSubMenu, layoutClassName)}>
      <button data-x='click-to-go-back' className={styles.subMenuBackButton} onClick={onPressBack}>
        <Icon icon={iconChevronLeftBoxed} />
      </button>

      <p className={styles.subMenuHeaderTitle}>
        {item.label}
      </p>

      <ul className={styles.subMenuList}>
        <Stagger initialXPosition={-5}>
          {submenuItems.map((item, i) =>
            <SubMenuItem key={i} {...{ item }} />
          )}
        </Stagger>
      </ul>
    </div>
  )
}

function SubMenuItem({ item }) {
  const { label, ref, params } = item ?? {}

  return (
    <li className={styles.componentSubMenuItem}>
      <a
        href={ref ? determineDocumentPathSyncWithParams({ document: ref, routeMap, params }) : ''}
        className={styles.subMenuItemAnchor}
        data-x={refToDataXLink(ref, { prefix: 'nav' })}
      >
        {label}
      </a>
    </li>
  )
}

function CloseIcon({ onClick, layoutClassName = undefined }) {
  return (
    <button data-x='click-to-close-mobile-menu' className={cx(styles.componentCloseIcon, layoutClassName)} {...{ onClick }}>
      <Icon icon={iconClose} />
    </button>
  )
}

function RitualsLogo({ layoutClassName = undefined }) {
  return (
    <a
      href="/"
      aria-label="Home"
      data-x="link-to-home"
      className={cx(styles.componentRitualsLogo, layoutClassName)}
    >
      <span role="presentation" dangerouslySetInnerHTML={{ __html: logo }} />
    </a>
  )
}

function Stagger({ children, initialXPosition }) {
  const [trail] = useTrail(children.length, i => ({
    config: { mass: 50, friction: 150, duration: 300 + i * 25, easing: easings.easeOutQuint },
    from: { opacity: 0, x: initialXPosition },
    to: { opacity: 1, x: 0 },
  }))

  return trail.map((x, i) => (
    <animated.div key={i} style={x} className={styles.componentStagger}>
      {children[i]}
    </animated.div>
  ))
}

function MenuList({ items, onItemSelected, layoutClassName = undefined }) {
  return (
    <ul className={cx(styles.componentMenuList, layoutClassName)}>
      <Stagger initialXPosition={5}>
        {items.map((x, i) => {
          if (x.menuItemType === 'subMenuItems') return (
            <MenuItem
              key={i}
              label={x.item?.label}
              icon={iconChevronRightBoxed}
              onClick={() => onItemSelected(x)}
              dataX={`nav-click-on-submenu-${x.item?.label ?? 'item'}`}
              layoutClassName={styles.menuParentItemLayout}
            />
          )

          if (x.menuItemType === 'self') return (
            <MenuItem
              key={i}
              label={x.item?.label}
              dataX={refToDataXLink(x.item?.ref, { prefix: 'nav' })}
              url={x.item?.ref
                ? determineDocumentPathSyncWithParams({ document: x.item.ref, routeMap, params: x.item.params })
                : ''
              }
            />
          )

          return null
        })}
      </Stagger>
    </ul>
  )
}

function MenuItem({ label, dataX = undefined, icon = undefined, url = undefined, onClick = undefined, layoutClassName = undefined }) {
  const Base = url ? 'a' : 'button'

  return (
    <Base
      data-x={dataX}
      className={cx(styles.componentMenuItem, layoutClassName)}
      {...(onClick ? { onClick } : {})}
      {...(url ? { href: url } : {})}
    >
      <span className={styles.labelLayout}>{label}</span>
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
    </Base>
  )
}

function useFloatingMenu() {
  const [isOpen, setIsOpen] = React.useState(false)
  const { refs, context, floatingStyles } = useFloating({
    onOpenChange: setIsOpen,
    open: isOpen
  })

  const role = useRole(context, {
    role: 'menu',
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    role,
  ])

  return {
    referenceProps: {
      ref: refs.setReference,
      ...getReferenceProps()
    },
    floatingProps: {
      ref: refs.setFloating,
      style: floatingStyles,
      ...getFloatingProps()
    },
    isOpen,
    setIsOpen
  }
}
