import { determineCountry } from './utils'
import { countrySettings } from '/i18n/countrySettings'
import { languageMapping } from '/i18n/languageMapping'

const countryLanguageLocales = createCountryLanguageLocaleLookup()

export const countryWithLocales = Object.entries(countrySettings).map(([countryCode, country]) => {
  return {
    countryTitle: country.title,
    flagIcon: country.flagIcon,
    languages: countryLanguageLocales[countryCode],
    countryCode,
  }
}).sort((a, b) => a.countryTitle.localeCompare(b.countryTitle))

function createCountryLanguageLocaleLookup() {
  return Object.values(languageMapping).reduce(
    (result, data) => {
      data.locales.forEach(language => {
        const country = determineCountry(language)

        country in result
          ? result[country].push({ language, title: data.title })
          : result[country] = [].concat({ language, title: data.title })
      })

      return result
    },
    {}
  )
}
