// Obtained from https://github.com/lipis/flag-icons/tree/main/flags/4x3
export { default as NL } from '/images/icons/flags/nl.raw.svg'
export { default as AT } from '/images/icons/flags/at.raw.svg'
export { default as BE } from '/images/icons/flags/be.raw.svg'
export { default as CH } from '/images/icons/flags/ch.raw.svg'
export { default as DE } from '/images/icons/flags/de.raw.svg'
export { default as DK } from '/images/icons/flags/dk.raw.svg'
export { default as FI } from '/images/icons/flags/fi.raw.svg'
export { default as FR } from '/images/icons/flags/fr.raw.svg'
export { default as IE } from '/images/icons/flags/ie.raw.svg'
export { default as IT } from '/images/icons/flags/it.raw.svg'
export { default as LU } from '/images/icons/flags/lu.raw.svg'
export { default as NO } from '/images/icons/flags/no.raw.svg'
export { default as PL } from '/images/icons/flags/pl.raw.svg'
export { default as PT } from '/images/icons/flags/pt.raw.svg'
export { default as ES } from '/images/icons/flags/es.raw.svg'
export { default as SE } from '/images/icons/flags/se.raw.svg'
export { default as GB } from '/images/icons/flags/gb.raw.svg'
export { default as RO } from '/images/icons/flags/ro.raw.svg'

