import { useScrollActivity } from '/machinery/useScrollActivity'
import { LanguageSelectionModal } from './LanguageSelection'
import { autoUpdate, useFloating } from '@floating-ui/react'
import { DesktopMenu } from './DesktopMenu'
import { MobileMenu } from './MobileMenu'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './Menu.css'

export function Menu({
  items,
  userInfo,
  translations,
  theme = cssStyleContext.contextWhite,
  breakpointOverride = undefined,
  layoutClassName = undefined
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <MenuTypeSwitch
        onOpenChange={handleOpenChange}
        {...{ items, theme, breakpointOverride, userInfo, translations, layoutClassName }}
      />
      <LanguageSelectionModal onOpenChange={handleOpenChange} {...{ open, translations }} />
    </>
  )

  function handleOpenChange() {
    setOpen(!open)
  }
}

function MenuTypeSwitch({ items, onOpenChange, theme, translations, userInfo, layoutClassName }) {
  const { hasScrolled, direction } = useScrollActivity({ delta: 100 })

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    transform: false,
    open: true,
  })

  return (
    <nav
      ref={refs.setFloating}
      style={floatingStyles}
      className={cx(
        styles.componentTypeSwitch,
        (!hasScrolled || direction === 'up') && styles.isOpen,
        theme === 'half-half' && styles['half-half'],
        layoutClassName
      )}
    >
      <DesktopMenu
        layoutClassName={styles.desktopMenuLayout}
        useLargeBreakpoint={theme === 'half-half'}
        {...{ theme, items, onOpenChange, userInfo }}
      />

      <MobileMenu
        layoutClassName={styles.mobileMenuLayout}
        theme={cssStyleContext.contextBlack}
        {...{ theme, items, onOpenChange, userInfo, translations }}
      />
    </nav>
  )
}
