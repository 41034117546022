import { TextXs } from '/features/buildingBlocks/Text'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import iconUser from '/images/icons/user.raw.svg'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './Logout.css'

export function Logout({ layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <a data-x='click-to-log-out' className={cx(styles.component, layoutClassName)} href={routeMap.api.v1.auth.logout()}>
      <Icon icon={iconUser} layoutClassName={styles.iconLayout} />
      <div data-style-context={cssStyleContext.contextBlack}>
        <TextXs text={__`logout`} />
      </div>
    </a>
  )
}
